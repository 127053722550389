import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { UserIcon, UsersIcon, CreditCardIcon } from "@heroicons/react/solid"
import { EnrollButton } from "../app-components/EnrollButton"

export const DetailedExamCard = ({ exam }) => {
  return (
    <div className="flex p-4 space-x-4 bg-white shadow rounded-xl">
      <div className="flex flex-col items-center justify-between text-xs">
        <Link
          to={`/app/exams/${exam.examSlug}`}
          className="w-12 h-12 mb-4 bg-white rounded-md"
        >
          <GatsbyImage
            image={exam.icon.localFile.childImageSharp.gatsbyImageData}
            alt={exam.examId}
          />
        </Link>
        <EnrollButton examId={exam?.examId} />
      </div>
      <Link
        to={`/app/exams/${exam.examSlug}`}
        className="relative flex flex-col flex-1 cursor-pointer "
      >
        <div className="absolute top-0 left-0 block px-3 text-xs text-blue-600 bg-blue-100 rounded ">
          {exam?.profession?.sector}
        </div>
        <button className="absolute top-0 right-0 hidden px-3 text-xs border border-gray-400 rounded ">
          Enroll
        </button>
        <div></div>
        <p className="mt-6 mb-2 text-lg font-base">{exam?.examName}</p>

        <div className="flex items-center text-xs text-gray-600 ">
          <CreditCardIcon className="w-3 h-3 mr-2" />
          <p>Rs {exam?.examData?.salary}/m starting salary</p>
        </div>
        <div className="flex items-center text-xs text-gray-600">
          <UserIcon className="w-3 h-3 mr-2" />
          <p>{exam?.examData?.vacancy}+ vacancy/year</p>
        </div>
        <div className="flex items-center text-xs text-gray-600">
          <UsersIcon className="w-3 h-3 mr-2" />
          <p>{exam?.examData?.totalApplicants / 100000} Lakh applicants</p>
        </div>
      </Link>
    </div>
  )
}
