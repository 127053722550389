import React, { useState } from "react"
import _ from "lodash"
import { DetailedExamCard } from "./DetailedExamCard"
import { useExamsQuery } from "../../context/static-queries/useExamsQuery"
import { motion } from "framer-motion"
import { myAnimations } from "../../context/framer-animations"
import PopularExams from "./../app-components/PopularExams"
import { ImList2 } from "react-icons/im"
import { BsFillGridFill } from "react-icons/bs"

const Exams = ({ examGroup = "", examIds = [] }) => {
  const [isGrid, setIsGrid] = useState(false)
  const exams = useExamsQuery()
  const sortedExams = _.sortBy(exams, ["examData.totalApplicants"]).reverse()
  let filterSortedExams = sortedExams

  if (examGroup.length) {
    filterSortedExams = _.filter(filterSortedExams, exam => {
      return exam.examgroups.some(examgroup => examgroup.name === examGroup)
    })
  }

  if (examIds.length) {
    filterSortedExams = _.filter(filterSortedExams, exam => {
      return examIds.some(examId => examId === exam.examId)
    })
  }

  return (
    <motion.div {...myAnimations.fadeIn} className="pb-24 m-5 space-y-4">
      <div className="flex justify-between mt-6 mb-4 ">
        <p className="font-base">{examGroup}</p>
        <div className="flex space-x-2">
          <button
            onClick={() => setIsGrid(false)}
            className={`p-1  rounded ${!isGrid && "bg-gray-800 text-white"}`}
          >
            <ImList2 className="w-6" />
          </button>
          <button
            onClick={() => setIsGrid(true)}
            className={`p-1  rounded ${isGrid && "bg-gray-800 text-white"}`}
          >
            <BsFillGridFill className="w-6" />
          </button>
        </div>
      </div>
      {!isGrid && (
        <motion.div {...myAnimations.fadeIn} className="space-y-4">
          {filterSortedExams?.map(exam => (
            <DetailedExamCard key={exam.examId} exam={exam} />
          ))}
        </motion.div>
      )}
      {isGrid && (
        <motion.div {...myAnimations.fadeIn}>
          <PopularExams limit={999} group={examGroup} />
        </motion.div>
      )}
      <div className="mt-6 mb-4 font-base"></div>
    </motion.div>
  )
}

export default Exams
