import React from "react"
import { Router } from "@reach/router"
import PrivateRoute from "../../components/context/privateRoute"
import Exams from "../../components/app/exams"
import AppLayout from "../../components/app/app-components/layout"

const AppExams = props => {
  const examGroup = props.location.state?.examGroup || ""
  const examIds = props.location.state?.examIds || ""
  return (
    <AppLayout
      isBackButton={true}
      title={examGroup || "All Exams"}
      path={props.path}
    >
      <Router>
        <PrivateRoute
          path="/app/exams"
          component={Exams}
          examGroup={examGroup}
          examIds={examIds}
        />
      </Router>
    </AppLayout>
  )
}

export default AppExams
